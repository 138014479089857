.select input {
  grid-area: inherit !important;
}

.dismiss {
  padding: 0;
}

.clearIcon {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subMenuList:not(:first-child) {
  border-top: 1px solid var(--colorTan3);
}

.headerContent {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

.subcomponentTitle {
  composes: ui11Medium from 'styles/typeography.css';
  color: var(--colorGray5);
  padding: 12px 16px 4px 16px;
  margin-bottom: 0;
}

.showAllResultsBtn {
  composes: ui11Medium from 'styles/typeography.css' !important;
  color: var(--colorYellow10) !important;
}

.hidden {
  display: none;
}

/* OPTIONS container styles */
/* Seller options */
.optionContainer {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.chevronRight {
  color: var(--colorTan7);
  margin-top: 4px;
}
.isFocused {
  color: var(--colorBlack2);
}
.optionDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
}
.optionDetails p {
  margin-bottom: 0;
}
.sellerLogo {
  width: 48px;
  height: 48px;
  border-radius: 3px;
}
.sellerInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.sellerName {
  composes: ui14Medium from 'styles/typeography.css';
  color: var(--colorBlack2);
}
.catalogName {
  composes: ui12Regular from 'styles/typeography.css';
  color: var(--colorGray6);
}

/* Product Options */
.productsListContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: auto;
}
.productSwipeContainer {
  padding: 0 16px 16px 16px;
}
.previewProductItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  overflow-wrap: break-word;
  max-width: 140px;
  width: 100%;
}
.productImage {
  width: 140px;
  height: 140px;
  border: 1px solid var(--colorGray2);
  border-radius: 3px;
  object-fit: cover;
}

.optionType {
  composes: ui12Regular from 'styles/typeography.css';
  color: var(--colorTextMuted);
}

/* Brand Value options */
.brandValue {
  composes: ui14Regular from 'styles/typeography.css';
  color: var(--colorBlack2);
}

/* Product Categories options */
.productCategories {
  display: inline;
  composes: ui14Regular from 'styles/typeography.css';
  color: var(--colorGray6);
  margin-right: 4px;
}
.productCategories p,
.productCategories a {
  margin-bottom: 0;
  display: inline;
}
.productName,
.productCategoryName {
  color: var(--colorBlack2);
  margin-bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.productName {
  max-width: 140px;
}

.url {
  color: var(--colorBlack2);
  text-decoration: none;
  cursor: default;
}
.url:hover {
  color: var(--colorBlack2);
}

.sponsoredBadge {
  color: var(--colorGold) !important;
  background-color: var(--colorTan2) !important;
}
